/** 繁體中文 */
export default {
  layout: {
    language: '語言：{name}',
    loginBtn: '立即登入/ 註冊 | 登入 / 註冊 | 立即登入',
    loginTip:
      '請註冊 / 登入為會員\n以觀看更多內容 | 請註冊 / 登入為會員以觀看更多內容 | 請註冊/登入以觀看更多內容',
    cancel: '取消 | 稍後',
    broadcastTitle: '特別公告',
    broadcastBtn: '確定',
    broadcastLabel: '不再顯示此消息。',
    areaNotice: {
      message: '此頁面並非您當前地區的頁面。\n您要切換到當前地區嗎？',
      jump: '帶我去吧！',
      messageMo: '此頁面不適用於您當前的地區。\n您要返回當前地區的主頁嗎？',
      jumpMo: '請跳轉到我的主頁'
    },
    tutorial: '教學',
    fqa: '常見問題',
    downloadApp: '下載',
    contactUs: '聯絡我們',
    infoSupport: '服務支援',
    setting: '設置'
  },
  home: {
    name: '主頁',
    more: '更多',
    errorPage: '進入錯誤頁面',
    seo: {
      title: () => 'TVB Anywhere | 海外官方網站',
      description:
        '帶給您TVB最新最熱門的電視劇、綜藝節目、 和直播等多種娛樂選擇，更有獨家幕後花絮、訪問、紀錄片等獨家內容，讓您身臨其境，暢享觀影體驗！'
    },
    episode1: '第{n}集',
    episode2: '更新至第{n}集',
    episode3: '全{n}集',
    episode4: '更新至{n}',
    episode5: '全{n}期',
    downloadDesc: '立即下載我們的應用程序以享受更多功能！'
  },
  vodplayer: {
    name: '点播頁'
  },
  vodzone: {
    name: '點播區',
    more: '更多',
    less: '收起',
    seo: {
      description: '享受TVB原創及其他外購劇集及綜藝節目'
    }
  },
  chlzone: {
    name: '直播頻道區',
    empty: '沒有可播放的頻道。'
  },
  player: {
    recommend: '推薦',
    fullScreen: '全屏顯示節目表',
    cast: '演員',
    as: '飾',
    episodes: '集數',
    playing: '播放中',
    nowplaying: '正在播放',
    episodeContents: '本集內容',
    synopsis: '節目內容',
    openinapp: '在APP中打開',
    basicmember: '免費會員',
    copied: '已複製',
    hour: '小時',
    day: '天',
    error: {
      nologin: '登入 / 註冊',
      more: '了解更多',
      free: '播放最新免費集數',
      chlEmpty: '沒有可播放的頻道。'
    },
    lastDayToWatch: '下架日期 ',
    liveDescription: ' = 7日 / 即時3小時回看'
  },
  epg: {
    name: '節目表',
    tip: '僅顯示有節目表的頻道',
    live: '直播中',
    playing: '回看中',
    today: '今天',
    seo: {
      title: () => '直播 | TVB Anywhere'
    }
  },
  error: {
    title404: '找不到頁面',
    des404: '抱歉，您要查找的頁面可能在您當前的位置不可用或不存在。',
    title500: '伺服器錯誤',
    des500: '抱歉，我們遇到了錯誤而未能處理您的請求。如果問題持續，請向 cs@tvbanywhere.com 查詢。',
    backHome: '返回主頁',
    empty: '未能找到相關資訊',
    titleUnsup: '改善您的瀏覽體驗',
    desUnsup: '您正在使用我們不支援的網路瀏覽器。嘗試使用以下瀏覽器之一以獲得更好的體驗。'
  },
  setting: {
    title: '設定',
    video: {
      quality: '影片質素',
      auto: '自動',
      high: '高',
      medium: '中',
      low: '低'
    },
    audio: {
      channel: '聲道語言',
      cantonese: '廣東話',
      mandarin: '普通話',
      english: '英語',
      thai: '泰語',
      vietnamese: '越南語',
      audio1: '聲道 1',
      audio2: '聲道 2'
    },
    subtitle: {
      language: '字幕語言',
      simplifiedChinese: '簡體中文',
      traditionalChinese: '繁體中文',
      bahasaIndonesia: '印尼文',
      english: '英文',
      vietnamese: '越南文',
      off: '關閉'
    },
    automaticallyPlayNextEpisode: '自動播放下一集',
    fontSize: '字體大小'
  },
  search: {
    search: '搜索',
    history: '搜索歷史',
    hot: '熱門關鍵字',
    audio: '影片聲道語言搜索',
    subtitle: '影片字幕語言搜索',
    channels: '直播頻道',
    programmes: '節目',
    recommendation: '推介',
    empty: '找不到相關結果',
    seo: {
      title: () => '搜索 | TVB Anywhere',
      description: '搜索演員名稱、劇集名稱、聲音或字幕語言'
    }
  },
  history: {
    name: '觀看紀錄',
    empty: '沒有任何觀看紀錄',
    delete: '刪除',
    select: '全選',
    deselect: '取消全選',
    more: '查看更多',
    seo: {
      title: () => '觀看紀錄 | TVB Anywhere',
      description: '觀看紀錄'
    }
  },
  favourite: {
    name: '我的收藏',
    empty: '沒有收藏任何節目',
    delete: '刪除',
    select: '全選',
    deselect: '取消全選',
    more: '查看更多',
    added: '添加到我的收藏',
    removed: '從我的收藏中移除',
    toast: {
      added: '添加到\n我的收藏',
      removed: '從我的收藏\n中移除'
    },
    down: '已下架',
    seo: {
      title: () => '我的收藏 | TVB Anywhere',
      description: '我已收藏劇集'
    }
  },
  static: {
    privacy: '隱私政策',
    privacyNoticeFull: '完整私隱聲明(歐盟)',
    privacyNoticeOverview: '私隱聲明概覽(歐盟)',
    cookiesPolicy: 'Cookies政策',
    cookiesSetting: 'Cookies設定',
    aboutUs: '關於我們',
    tutorial: '教學',
    tutorialStb: 'TVB Anywhere 機頂盒',
    tutorialApp: 'TVBAnywhere+應用程式跨平台',
    faq: '常見問題',
    contactUs: '聯繫我們',
    partnersDealers: '銷售伙伴/海外分銷商聯絡資料',
    termsOfService: '使用條款',
    paymentTermsAndConditions: '訂閱條款及細則',
    jetsoTermsAndConditions: '3zone 服務條款'
  },
  subscribe: {
    sectionA: {
      title: '隨時隨地\n盡享源源不絕的精彩劇集和綜藝節目',
      travel_pass_title:
        '擔心去旅行無得煲劇？<span style="color: #ff4b03;">TVB Anywhere旅遊通</span>幫您隨時隨地\n緊貼TVB精彩內容超過40,000小時點播節目，服務範圍遍佈全球*，令您旅途添上無盡精彩!',
      desc: '立即選擇你的服務計劃',
      travel_pass_desc: '*此服務不適用於中國內地、香港、澳門、美國及加拿大',
      tip: '訂閱一年計劃即享有超過15% 折扣優惠 （對比每月連續訂閱月費計劃12次）',
      month: '月 | {n}個月 | {n}個月',
      daily: '天 | {n}天',
      year: '年'
    },
    sectionB: {
      add: '額外\n加購',
      btn: '立即訂閱',
      click: '按此',
      more: '了解更多'
    }
  }
}
