<script setup name="TvbAds">
// 广告需求 https://tvbco.sharepoint.com/:p:/r/sites/Project-Ark2/_layouts/15/Doc.aspx?sourcedoc=%7B7094F76F-6F33-474E-8137-83CD9E5DC563%7D&file=Ad%20position%20on%20TVBAW%20WebTV_20230217.pptx&action=edit&mobileredirect=true
import { onBeforeUnmount, onMounted, ref, inject, watch } from 'vue'
import { generateMixed } from '@/utils/index.js'
import { useAds } from '@/utils/ads.js'
import dayjs from 'dayjs'

const { getCustomParameters, unitPrefix } = useAds()
const emits = defineEmits(['slotOnload'])
const width = inject('width')

const props = defineProps({
  // 广告id 自定义且唯一
  id: {
    type: String,
    default: () => generateMixed(16)
  },
  // 广告类型 bn(banner)/lrec
  adtype: {
    type: String,
    required: true
  },
  /** 页面名称，用来查询size 具体参数参见其他参数url */
  page: {
    type: String,
    required: true
  },
  // 广告尺寸 320x50|320x100|... 这种格式 优先采用该值
  size: {
    type: String,
    default: null
  },
  // 广告其他参数 具体见https://tvbco.sharepoint.com/sites/Project-Ark2/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=bPaSfK&ovuser=ca1293ac%2Dd322%2D43f4%2Dae3b%2De18e5fa51fb9%2Cjay%2Ehuang%40tvb%2Ecom&OR=Teams%2DHL&CT=1680055165994&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMzAzMDUwMTEwNSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&cid=e90e7ce0%2D4dfe%2D405b%2D86bb%2D6e55a38be716&FolderCTID=0x012000A93D6667D300DD458E28A2CC747A4E30&id=%2Fsites%2FProject%2DArk2%2FShared%20Documents%2FV5%2DGlobal%2F00%2DFS%2F2023%20%2D%20WebTV%2FAdUnitSpec%20for%20Anywhere%2B%20WebTV%20%2D%20v1%2E2%2Epdf&parent=%2Fsites%2FProject%2DArk2%2FShared%20Documents%2FV5%2DGlobal%2F00%2DFS%2F2023%20%2D%20WebTV
  params: {
    type: Object,
    default: () => {}
  }
})

const computedOldWidth = (width) => {
  if (width > 1024) {
    return 1920
  } else if (width <= 1024 && width > 800) {
    return 1024
  } else if (width <= 800) {
    return 800
  }
}

const tvbAdsRef = ref()
const io = ref()
const idAds = ref(false)
const adsShow = ref(false)
const customParameters = ref()
const oldWidth = ref(computedOldWidth(width.value))

/** 渲染广告 */
const renderAd = (googletag = window.googletag) => {
  googletag.cmd.push(function () {
    const el = document.createElement('div')
    el.id = `tvb-ads_${props.adtype}_${props.id}_${dayjs().valueOf()}`
    el.classList.add('tvb-ads_container-content')
    if (document.getElementById(props.id)) {
      document.getElementById(props.id).innerHTML = ''
      document.getElementById(props.id).appendChild(el)
    }
    googletag.pubads().enableSingleRequest()
    googletag.enableServices()
    googletag.pubads().setCentering(true)
    if (!customParameters.value?.adtype) {
      customParameters.value = getCustomParameters({
        ...props.params,
        adtype: props.adtype,
        page: props.page
      })
    } else {
      customParameters.value.size = getCustomParameters({
        ...props.params,
        adtype: props.adtype,
        page: props.page
      }).size
    }

    const adSize = (props.size || customParameters.value.size)?.split('|')?.reduce((p, c) => {
      const [w, h] = c.split('x')
      p.push([parseInt(w), parseInt(h)])
      return p
    }, [])
    const slot = googletag
      .defineSlot(`${unitPrefix}/${props.adtype}`, adSize, el.id)
      ?.addService(googletag.pubads())

    for (const key in customParameters.value) {
      if (key !== 'size') {
        googletag?.pubads()?.setTargeting(key, customParameters.value[key])
      }
    }
    slot?.setTargeting('adtype', customParameters.value.adtype)
    slot?.addService(googletag.pubads())
    googletag.display(el.id)
    if (import.meta.env.VITE_ENV !== 'prod') {
      console.log({
        adId: el.id,
        adUnitPrefix: `${unitPrefix}/${props.adtype}`,
        adCustomParameters: { ...customParameters.value, size: adSize }
      })
    }
    googletag.pubads().addEventListener('slotOnload', function (event) {
      const slotId = event.slot.getSlotElementId()
      try {
        if (document?.querySelector(`#${props.id} iframe[id*="google_ads_iframe_"]`)) {
          emits('slotOnload', slotId)
          adsShow.value = true
        }
      } catch (error) {
        console.error(error)
        emits('slotOnload', slotId)
        adsShow.value = true
      }
    })
  })
}

onMounted(() => {
  io.value = new IntersectionObserver((entries) => {
    if (entries[0].isIntersecting) {
      try {
        if (!idAds.value) renderAd()
        idAds.value = true
        io.value.unobserve(tvbAdsRef.value)
        io.value.disconnect()
      } catch (error) {
        console.error(error)
      }
    }
  })
  if (tvbAdsRef.value) io.value?.observe(tvbAdsRef.value)
})

onBeforeUnmount(() => {
  io.value?.disconnect()
})

watch(width, (width) => (oldWidth.value = computedOldWidth(width)))

watch(oldWidth, () => renderAd())
</script>

<template>
  <div class="tvb-ads" ref="tvbAdsRef" :class="{ show: adsShow }">
    <div class="tvb-ads_container" :id="id"></div>
  </div>
</template>

<style lang="scss" scoped>
.tvb-ads {
  width: 100%;
  height: auto;
  opacity: 0;
  transition: opacity 500ms;

  &.show {
    opacity: 1;
  }

  .tvb-ads_container {
    margin: 0 auto;
    width: inherit;
    height: 100%;
    overflow: hidden;
  }
}
</style>
