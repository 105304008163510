<script setup name="TvbImage">
import { onBeforeUnmount, onMounted, ref, computed, nextTick } from 'vue'
import { NSkeleton } from 'naive-ui'
import ErrorImg from './error.svg'

/** TvbImage */
const props = defineProps({
  // 图片地址
  src: {
    type: String,
    default: ''
  },
  // 压缩图
  comSrc: {
    type: String,
    default: ''
  },
  // 错误图
  errorSrc: {
    type: String,
    default: ErrorImg
  },
  // 图片集
  images: {
    type: Array,
    default: () => []
  },
  // h 横图 v 竖图
  position: {
    type: String,
    default: 'h'
  }
})

const showImage = ref(false)
const imageShow = ref(false)
const tvbImageRef = ref()
const tvbImageIo = ref()
const imageError = ref(false)
const imageSrc = computed(() => {
  const imageObj = {
    ori: props.src || '', // 原图
    org: props.src || '', // 原图字段2
    com: props.comSrc || props.src || '' // 压缩
  }
  if (props.images.length) {
    const imageSizes = []
    props.images.forEach((image) => {
      if (image.position === props.position) {
        if (!isNaN(parseInt(image.image_type))) {
          imageObj[parseInt(image.image_type)] = image.image_path || ''
          imageSizes.push(parseInt(image.image_type))
        } else {
          imageObj[image.image_type] = image.image_path || ''
        }
      }
    })
    try {
      imageObj.com = imageObj[Math.min(...imageSizes)]
    } catch (error) {
      console.error(error)
    }
    imageObj.ori = imageObj.ori || imageObj.org || imageObj.com || ''
    if (!imageObj.com) {
      imageObj.com = imageObj.ori
    }
  } else if (!props.src) {
    imageObj.ori = props.errorSrc
    nextTick(() => {
      showImage.value = true
      imageError.value = true
    })
  }
  return imageObj
})

onMounted(() => {
  /** 当进入视野的开始加载图片 */
  tvbImageIo.value = new IntersectionObserver((entries) => {
    if (entries?.[0]?.isIntersecting || entries?.[1]?.isIntersecting) {
      imageShow.value = true
      tvbImageIo.value.unobserve(tvbImageRef.value)
      tvbImageIo.value.disconnect()
    }
  })
  tvbImageIo.value.observe(tvbImageRef.value)
})

onBeforeUnmount(() => {
  tvbImageIo?.value?.unobserve(tvbImageRef?.value)
  tvbImageIo?.value?.disconnect()
})
</script>

<template>
  <div
    class="tvb-image"
    :class="{ 'image-error': imageError, 'image-errors': errorSrc === ErrorImg }"
    ref="tvbImageRef"
  >
    <!-- v-if="showImage" -->
    <img
      :src="imageSrc.ori"
      @error=";(imageSrc.ori = errorSrc), (imageError = true)"
      class="tvb-image_img"
      :class="[position]"
      loading="lazy"
      v-bind="$attrs"
    />
    <!-- <img
      v-if="imageShow && !imageError"
      @load="showImage = true"
      @error=";(showImage = true), (imageSrc.ori = errorSrc), (imageError = true)"
      class="tvb-image_base-map"
      :style="{ opacity: showImage ? 1 : 0 }"
      loading="lazy"
      :src="imageSrc.com"
    /> -->
    <div
      class="tvb-image_underpainting-color"
      :style="{
        backgroundColor: imageError ? 'rgba(0,0,0,0.5)' : undefined
      }"
    >
      <n-skeleton v-if="!imageError" height="100%" width="100%" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tvb-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);

  &.image-error {
    text-align: center;
    .tvb-image_img {
      width: 100%;
    }
    &.image-errors {
      .tvb-image_img {
        width: 55%;
        object-fit: contain;
        &.h {
          width: 35%;
        }
      }
    }
  }

  .tvb-image_img {
    width: 100%;
    height: 100%;
    position: relative;
    // z-index: 1;
    object-fit: cover;
  }

  &::before {
    content: '';
    display: none;
    position: absolute;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    z-index: 1;
  }

  .tvb-image_base-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 500ms;
    z-index: -1;
    display: none;
  }
  .tvb-image_underpainting-color {
    position: absolute;
    display: none;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}
</style>
