<script setup name="TvbRouterLink">
/**
 * 二次封裝VueRouterLink
 * 用來對頁面跳轉path進行處理
 * 支持RouterLink所有參數
 */
import { computed } from 'vue'
import { RouterLink } from 'vue-router'
// import { useAppStore } from '@/stores/app'
import { disposePath } from '@/utils/index.js'
import { checkInstationSite } from '@tvb-sz/tvb-anywhere-headerfooter/lib/utils/index'

// const appStore = useAppStore()
// const countryCode = computed(() => appStore?.config?.geoInfo?.country || '')
// const languageCode = computed(() => appStore?.currentLanguage?.code || '')
// const platform = import.meta.env.VITE_APP_PLATFORM || ''

const props = defineProps({
  to: {
    type: [String, Object],
    default: null
  }
})

/** 調整to 糾正錯誤的參數  */
const jumpTo = computed(() => {
  let [c, l] = location.pathname.split('/').filter((s) => s)
  if (['tc', 'sc', 'en'].includes(c)) l = c
  let to = props.to
  if (typeof to === 'string') {
    to = disposePath(to, c, l)
  }
  if (to?.path) {
    to.path = disposePath(to.path, c, l)
  }
  if (to?.fullPath) {
    to.fullPath = disposePath(to.fullPath, c, l)
  }
  if (to?.query?.language) {
    to.query.language = l
  }
  return to
})
</script>

<template>
  <component
    :is="to.includes('://') ? 'a' : RouterLink"
    v-bind="{
      ...$attrs,
      ...(to.includes('://')
        ? {
            href: to,
            target: checkInstationSite(to) ? undefined : '_blank'
          }
        : { to: jumpTo })
    }"
  >
    <slot />
  </component>
</template>
