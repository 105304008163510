<script setup>
import { TvbAnywhereSsoButton } from '@tvb-sz/tvb-anywhere-headerfooter'
defineProps({
  show: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'favourite'
  }
})
const emit = defineEmits(['update:show'])
const handleClose = () => {
  emit('update:show', false)
}
</script>

<template>
  <teleport to="body">
    <transition name="tvb-fade">
      <div v-show="show" class="tvb-login-dialog-mask">
        <div class="tvb-login-dialog-box">
          <div class="tvb-login-dialog-content">
            <p class="tvb-login-panel_description">{{ $tc('layout.loginTip', 2) }}</p>
          </div>
          <div class="tvb-login-dialog-footer">
            <tvb-anywhere-sso-button
              class="tvb-login-panel_sso-button"
              type="USER_LOGIN"
              tag="button"
              @click="handleClose"
            >
              {{ $tc('layout.loginBtn', 2) }}
            </tvb-anywhere-sso-button>
            <n-button class="btn-cancel" strong secondary @click="handleClose">{{
              $tc('layout.cancel', 2)
            }}</n-button>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<style scoped>
.tvb-login-dialog-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tvb-login-dialog-box {
  padding: 30px 0;
  background-color: #303030;
  width: 358px;
  max-width: 95%;
  border-radius: 10px;
  overflow: hidden;
}

.tvb-login-dialog-content {
  text-align: center;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.tvb-login-panel_description {
  color: #e5e5e5;
  font-size: 18px;
  line-height: 27px;
  padding: 0 10px;
}

.tvb-login-dialog-footer {
  text-align: center;
}

:deep(.btn-cancel) {
  --n-border-radius: 4px !important;
  --n-font-size: 16px !important;
  --n-height: 36px !important;
  --n-color-hover: #858585 !important;
  color: #fff !important;
  font-weight: 500;
  background-color: #858585;
  outline: none;
  width: 250px;
  margin-top: 12px;
}

:deep(.tvb-login-panel_sso-button) {
  height: 36px;
  width: 250px;
  border-radius: 4px;
  background-color: #67bb55;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  outline: 0;
  border: 0;
  cursor: pointer;
  padding: 0;
  margin-top: 20px;
}

@media (max-width: 834px) {
  .tvb-login-dialog-content {
    padding: 0 50px;
  }
  .tvb-login-panel_description {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 390px) {
  .tvb-login-dialog-box {
    padding: 26px 0;
    width: 302px;
  }
  .tvb-login-dialog-content {
    padding: 0 20px;
  }
}
</style>
