import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useAppStore } from '@/stores/app'
/** https://github.com/vueuse/head */
import { createHead } from '@vueuse/head'
/** https://www.naiveui.com/zh-CN/light/components/discrete */
import { createDiscreteApi } from 'naive-ui'
// import { disposeRouterLanguage } from './utils/index'
import Cookies from 'js-cookie'
import qs from 'qs'
/** 国际化配置 */
import vueI18n from '@/locale'

import App from './App.vue'
import router from './router'
import TvbAds from '@/components/TvbAds/index.vue'
import TvbImage from '@/components/TvbImage/index.vue'
import TvbSvgIcon from '@/components/TvbSvgIcon/index.vue'
import TvbResponsive from '@/components/TvbResponsive/index.vue'
import TvbRouterLink from '@/components/TvbRouterLink/index.vue'

import './assets/style/index.scss'
/** https://www.naiveui.com/zh-CN/light/docs/fonts */
import 'vfonts/Lato.css'
import 'virtual:svg-icons-register'
import '@tvb-sz/tvb-anywhere-headerfooter/theme-default/index.css'

const app = createApp(App)
const pinia = createPinia()

app.use(vueI18n)
app.use(pinia)
app.use(router)
app.use(createHead())

app.component('tvb-ads', TvbAds)
app.component('tvb-image', TvbImage)
app.component('tvb-svg-icon', TvbSvgIcon)
app.component('tvb-responsive', TvbResponsive)
app.component('tvb-router-link', TvbRouterLink)

const appStore = useAppStore()
const { loadingBar } = createDiscreteApi(['loadingBar'], {
  loadingBarProviderProps: {
    loadingBarStyle: {
      loading: 'background: #67BB55;height: 2px;'
    }
  }
})

router.beforeEach(async (to, from, next) => {
  loadingBar.start()
  if (Object.keys(to?.query || {})) {
    const query = to?.query || {}
    const utmParams = {}
    for (const key in query) {
      if (key.indexOf('utm_') === 0) {
        utmParams[key] = query[key]
      }
    }
    if (Object.keys(utmParams)) {
      Cookies.set('utm', qs.stringify(utmParams))
    }
  }
  if (!to.name.includes('Error')) {
    try {
      if (!appStore.config.geoInfo) await appStore.getDevicesGeoInfo()
      if (appStore.config?.geoInfo?.webtv_block && !['subscribe', 'static'].includes(to.name)) {
        next(`/${to.params.language}/${to.params.platform}/subscribe`)
      }
    } catch (error) {
      next('/404')
    }
  }
  appStore.adCount = { banner: 1, lrec: 1 }
  appStore.setCurrentLanguage(null, false)
  next()
  /** 处理语言及地区 */
  // if (['qa', 'prod'].includes(import.meta.env.VITE_ENV)) {
  //   next()
  // } else if (!from.name || to.path === '/') {
  //   disposeRouterLanguage(to, from, next, appStore)
  // } else if (appStore.config.geoInfo?.country === 'MO' && to.path.indexOf('/mo') !== 0) {
  //   next(`/mo${to.fullPath}`)
  // } else if (to.path.indexOf('/mo') === 0) {
  //   next(to.fullPath.replace('/mo', ''))
  // } else {
  //   next()
  // }
})

router.afterEach((to) => {
  if (to.name.includes('Error')) {
    loadingBar.error()
  } else {
    loadingBar.finish()
  }
})

app.mount('#tvb-anywhere')
