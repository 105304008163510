<template>
  <component
    :is="props.tag"
    ref="tvbResponsiveRef"
    class="tvb-responsive"
    :class="{ mobile: isMobile, tablet: isTablet }"
  >
    <slot
      v-if="slots.mobile && isMobile"
      :responsive="{ isMobile, width, height, isTablet }"
      name="mobile"
    ></slot>
    <slot
      v-if="slots.tablet && isTablet"
      :responsive="{ isMobile, width, height, isTablet }"
      name="tablet"
    ></slot>
    <slot v-else :responsive="{ isMobile, width, height, isTablet }"></slot>
  </component>
</template>

<script setup name="AnyResponsive">
/**
 * 響應式容器
 * 提供兩個插槽pc/mobile 默認pc 在有mobile傳入時會自動切換
 * 元素設置class=[tvb-pc|tvb-mobile]會自動顯示隱藏
 */
import { ref, inject, watch, useSlots } from 'vue'

const slots = useSlots()
const emits = defineEmits(['change'])
const isTablet = inject('isTablet')
const isMobile = inject('isMobile')
const width = inject('width')
const height = inject('height')
const tvbResponsiveRef = ref()

watch([isMobile, width, height], ([isMobile, width, height, isTablet]) =>
  emits('change', { isMobile, width, height, isTablet })
)

const props = defineProps({
  tag: {
    type: String,
    default: 'div'
  }
})

defineExpose({
  el: tvbResponsiveRef
})
</script>

<style lang="scss" scoped></style>
