import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs.prod'
import en from './en'
import zhcn from './zh-cn'
import zhhk from './zh-hk'

/** 多语言 */
const i18n = createI18n({
  locale: import.meta.env.VITE_DEFAULT_LANGUAGE,
  messages: {
    'en-US': en,
    'zh-CN': zhcn,
    'zh-HK': zhhk
  }
})

export default i18n
