import { reportException } from '@/apis/app.js'

/**
 * 处理路由语言
 * @param {*} to
 * @param {*} from
 * @param {*} next
 */
export function disposeRouterLanguage(to, _, next, appStore) {
  /** 获取默认语言 */
  let language = ''
  if (to.params.language) {
    language = appStore.filterLanguage('code', to.params.language)
  } else if (localStorage.getItem('currentLanguage')) {
    language = appStore.filterLanguage('key', localStorage.getItem('currentLanguage'))
  } else if (appStore.config.geoInfo?.country) {
    language = appStore.filterLanguage('country', appStore.config.geoInfo?.country)
  }
  if (!language) {
    language = appStore.language.map.get('en-US')
  }
  /** 设置语言 */
  appStore.setCurrentLanguage(language.key, false)
  /** 处理地区 */
  if (to.name === 'Home' && !to.path.includes('webtv')) {
    if (appStore.config.geoInfo?.country === 'MO' && to.path.indexOf('/mo') !== 0) {
      next(`/mo/${language.code}/webtv`)
    } else {
      next(`/${language.code}/webtv`)
    }
  } else if (to.path.indexOf('/mo') === 0) {
    next(to.fullPath.replace('/mo', ''))
  } else {
    next()
  }
}
/**
 * 生成n位数字字母混合字符串
 * @param {number} n 字符串的长度
 * @returns
 */
export function generateMixed(n) {
  const chars = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ]
  let res = ''
  for (let i = 0; i < n; i++) {
    const id = Math.floor(Math.random() * 36)
    res += chars[id]
  }
  return res
}

// 获取设备信息
export const getDeviceInfo = () => {
  // 设备信息来源 http://mapi.youbora.com:8081/devices
  // https://juejin.cn/post/6844903874113830920
  const ua = navigator.userAgent.toLowerCase()
  const testUa = (regexp) => regexp.test(ua)
  if (testUa(/windows|win32|win64|wow32|wow64/g)) {
    return {
      id: 2,
      name: 'PC( Windows )',
      code: 'PCWindows',
      ad: 'pc',
      device: 'pc'
    }
  } else if (testUa(/macintosh|macintel/g)) {
    return {
      id: 3,
      name: 'PC( MAC )',
      code: 'PCMac',
      ad: 'pc',
      device: 'pc'
    }
  } else if (testUa(/x11/g)) {
    return {
      id: 1,
      name: 'PC( Linux )',
      code: 'PCLinux',
      ad: 'pc',
      device: 'pc'
    }
  } else if (testUa(/android|adr/g)) {
    return {
      id: 11,
      name: 'Android',
      code: 'Android',
      ad: 'android',
      device: 'mobile'
    }
  } else if (testUa(/ios/g)) {
    return {
      id: 48,
      name: 'iOS',
      code: 'iOS',
      ad: 'ios',
      device: 'mobile'
    }
  }
  return {
    id: '',
    name: '',
    ad: 'pc',
    code: ''
  }
}

/**
 * 处理异常上报
 * @param {error}  error       错误信息
 * @param {string} message     自定义信息以便快速查找
 * @param {string} errorType   錯誤類型 info, warning, error
 */
export const catchException = (error, message = '', errorType = 'error') => {
  const apiUrl =
    error?.request?.responseURL || `${error?.config?.baseURL}${error?.config?.url}` || ''
  if (apiUrl && !apiUrl.includes('/api/exception')) {
    console.error(apiUrl, error)
    reportException({
      url: location.href,
      error_type: errorType,
      error: JSON.stringify({
        code: error?.code,
        apiUrl,
        referrerUrl: document.referrer || '',
        message: message + (error?.message || ''),
        name: error?.name || '',
        httpStatus: error?.status || error?.response?.status || 0,
        responseText: error?.request?.responseText || '',
        getParams: error?.config?.params || {},
        postData: error?.config?.data || {},
        stack: error.stack || ''
      })
    })
  } else {
    console.warn(error)
  }
}

export function setLocalStorage(key, value) {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  localStorage.setItem(key, value)
}

export function getLocalStorage(key) {
  const value = localStorage.getItem(key)
  return JSON.parse(value)
}

export function setSessionStorage(key, value) {
  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  sessionStorage.setItem(key, value)
}

export function getSessionStorage(key) {
  const value = sessionStorage.getItem(key)
  return JSON.parse(value)
}

//将秒转成时分秒格式
export const getTime = (time, show = true) => {
  // 转换为式分秒
  let h = parseInt((time / 60 / 60) % 24)
  h = h < 10 ? '0' + h : h
  let m = parseInt((time / 60) % 60)
  m = m < 10 ? '0' + m : m
  let s = parseInt(time % 60)
  s = s < 10 ? '0' + s : s
  if (show) return h + ':' + m + ':' + s
  else return h === '00' ? m + ':' + s : h + ':' + m + ':' + s
}

/** 處理糾正path */
export const disposePath = (path) => {
  // console.log(path)
  if (path.includes('://')) return path
  let [country, language] = location.pathname.split('/').filter((s) => s)
  if (['tc', 'sc', 'en'].includes(country)) language = country
  // console.log(country, language)
  if (path.indexOf('/') !== 0) path = `/${path}`.replace('//', '/')
  if (['tc', 'sc', 'en'].includes(language)) {
    return `${country === 'mo' ? '/mo' : ''}/${language}/${import.meta.env.VITE_APP_PLATFORM}${
      path === '/' ? '' : `/${path}`
    }`.replace('//', '/')
  }
  // console.log(path, country, language)
  return path
}

/**
 * 复制字符串到粘贴板
 * @param {string} str 将要复制的字符串
 */
export const copyString = (str) => {
  const copyDom = document.createElement('textarea')
  copyDom.value = str
  document.body.appendChild(copyDom)
  return new Promise((resolve) => {
    setTimeout(() => {
      try {
        copyDom.select()
        document.execCommand('Copy')
        document.body.removeChild(copyDom)
        resolve(true)
      } catch (err) {
        resolve(false)
      }
    }, 100)
  })
}

/**
 * 获取当前浏览器环境
 */
export const getBroswer = () => {
  let ua = navigator.userAgent.toLowerCase()
  if (ua.match(/huaweibrowser\/([\d.]+)/)) return ''
  if (ua.match(/edg\/([\d.]+)/)) return 'Edge'
  if (ua.match(/chrome\/([\d.]+)/)) return 'Chrome'
  if (ua.match(/version\/([\d.]+).*safari/)) return 'Safari'
  if (ua.match(/crios\/([\d.]+)/)) return 'Chrome' // Chrome on iOS
  return ''
}

export const isMobile = () => /Mobi|Android|iPhone|iPad/i.test(navigator.userAgent)

/**
 * 拼接路径前缀
 * @param {string} path 路径
 */
export const getPath = (path) => {
  let [c, l] = location.pathname.split('/').filter((s) => s)
  if (['tc', 'sc', 'en'].includes(c)) l = c
  return `${c === 'mo' ? '/mo' : ''}/${l}/${import.meta.env.VITE_APP_PLATFORM}${path || ''}`
}

export const historyReplaceState = (path) => {
  window.history.replaceState(history.state, null, disposePath(path || ''))
}

export const getSeoLinks = (href = '') => {
  return [
    {
      key: 'canonical',
      rel: 'canonical',
      href: href
    },
    {
      key: 'alternate-x-default',
      rel: 'alternate',
      hreflang: 'x-default',
      href: href
    },
    {
      key: 'alternate-en-US',
      rel: 'alternate',
      hreflang: 'en-US',
      href: href.replace(/(\/en\/|\/sc\/|\/tc\/)/g, '/en/')
    },
    {
      key: 'alternate-zh-cn',
      rel: 'alternate',
      hreflang: 'zh-cn',
      href: href.replace(/(\/en\/|\/sc\/|\/tc\/)/g, '/sc/')
    },
    {
      key: 'alternate-zh-tw',
      rel: 'alternate',
      hreflang: 'zh-tw',
      href: href.replace(/(\/en\/|\/sc\/|\/tc\/)/g, '/tc/')
    }
  ]
}

// 移除对象的undefined、null属性
// return 新对象
export const objDelEmpty = (obj) => {
  return (
    Object.entries(obj)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, v]) => v != null)
      .reduce((a, [k, v]) => ({ ...a, [k]: v === Object(v) ? objDelEmpty(v) : v }), {})
  )
}
