import { ref } from 'vue'
import { getDeviceInfo } from './index'
import { adsConfig } from './../constant/index'
import { useUserStore } from '@/stores/user.js'
import { useAppStore } from '@/stores/app.js'
import qs from 'qs'

export function useAds() {
  const env = import.meta.env.VITE_ENV
  const unitPrefix = `/21614262448/awv5.webtv.${getDeviceInfo().ad}.${env === 'prod' ? 'prod' : 'dev'}`
  const size = ref()
  const { userCustomer } = useUserStore()
  const { adCount } = useAppStore()

  /** 获取自定义参数 */
  const getCustomParameters = (params = {}) => {
    const customParameters = { dtype: '', adtype: '', page: '', user: '', main: '', lib: '', attr: '', programme: '', episode: '', ed: '', ch: '', ...params }

    /** 获取page对应的size */
    if (customParameters.page) {
      const sizes = adsConfig.get('sizes')[params.adtype]
      for (const pages in sizes) {
        if (Object.hasOwnProperty.call(sizes, pages) && pages.includes(customParameters.page)) {
          const size = sizes[pages];
          const width = window.innerWidth
          const widths = Object.keys(size)
          for (let index = 0; index < widths.length; index++) {
            const minWidth = widths[index];
            const maxWidth = widths[index + 1] || Infinity;
            if (minWidth <= width && width < maxWidth) {
              customParameters.size = size[minWidth]
              customParameters.dtype = ['mobile', 'tablet', 'web'][index]
            }
          }
        }
      }
    }
    if (customParameters.adtype === 'bn') {
      customParameters.adtype = `banner${adCount.banner}`
      adCount.banner++
    }
    if (customParameters.adtype === 'lrec') {
      customParameters.adtype = `lrec${adCount.lrec}`
      adCount.lrec++
    }
    customParameters.user = userCustomer?.customer_stage || 'guest'
    for (const key in customParameters) !customParameters[key] && delete customParameters[key]
    return customParameters
  }

  /** 视频广告url */
  const getAdVideoTagUrl = (params = {}) => {
    const adTagUrl = new URL(`${import.meta.env.VITE_AD_GAM_BASE_URL}`)
    const customParameters = getCustomParameters({ page: 'player', adtype: 'video', ...params })
    const parameters = {
      env: 'vp',
      'gdfp_req': 1,
      'unviewed_position_start': 1,
      'output': 'vmap',
      'ad_rule': 1,
      'cmsid': '2531129',
      vid: customParameters.vid,
      iu: `${unitPrefix}/${customParameters.adtype}`,
      sz: customParameters.size,
      'description_url': encodeURIComponent(location.href),
      correlator: new Date().getTime(),
      'cust_params': qs.stringify(customParameters),
      'ppid': userCustomer?.account_id || ''
    }
    for (const key in parameters) parameters[key] && adTagUrl.searchParams.append(key, parameters[key])

    if (import.meta.env.VITE_ENV !== 'prod') {
      console.log({ adVideoTagUrl: adTagUrl, parameters })
    }
    return adTagUrl.href
  }

  return { getCustomParameters, getAdVideoTagUrl, unitPrefix, size }
}