import { defineStore } from 'pinia'
import { reactive, computed } from 'vue'

/** user */
export const useUserStore = defineStore('user', () => {

  const user = reactive({
    info: {}, // tvb用户信息
    token: '',
    // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTgzNzM2MzAsImV4cCI6MTY5ODQ1NjQzMCwibmJmIjoxNjk4MzczNjMwLCJkZXZpY2VfaWQiOiIyMTkwNDkwIiwiZGV2aWNlX29zIjoid2ViIiwiZGV2aWNlX3JvbGUiOm51bGwsInBsYXRmb3JtIjoiYXBwIiwiZGV2aWNlX2xhbmd1YWdlIjoiaGsiLCJkcm1faWQiOiIiLCJhcHBfdHlwZSI6InNnIiwibGlmZXRpbWVfaWQiOiJ3ZWItdW5rbm93biIsImRldmljZV90eXBlIjoicHJvZCIsInR2Yl9hY2NvdW50X2lkIjoiNTgxMDY1IiwidXNlcl9pZCI6Ijk4MDMiLCJ1c2VyX25pY2tuYW1lIjoiYXdoZXJlMDEwKzAwOCIsInVzZXJfdGh1bWJuYWlsX2ltYWdlIjoiIiwidXNlcl9iYWNrZ3JvdW5kX2ltYWdlIjoiIiwidXNlcl9sZXZlbCI6IiIsInVzZXJfYmFkZ2UiOiIiLCJtX3Rva2VuIjoiIiwib3ZlcnJpZGVfY291bnRyeV9jb2RlIjoiU0cifQ.fGHIqC9GF-28jnnJ7Q3a3BEuwenYzd0epkvY2RWD_Yw", // token
    customer: {}, // aw用户
    subscriptions: [], // aw用户订阅
    guestInfo: {}, // 游客信息
    guestToken: '', // 游客token
    categories: {
      attribute: [],
      cp_cat: [],
      lib: [],
      main_cat: []
    }
  })

  const userInfo = computed(() => user.info)
  const userCustomer = computed(() => user.customer)
  const userSubscriptions = computed(() => user.subscriptions)
  const userCategories = computed(() => user.categories)

  const token = computed(() => user.token)

  const guestInfo = computed(() => user.guestInfo)


  const guestToken = computed(() => user.guestToken)


  const setToken = (token) => {
    user.token = token
  }

  const setUserInfo = (info) => {
    user.info = info
    if (info.customer) user.customer = info.customer
    if (info.subscriptions) user.subscriptions = info.subscriptions
    if (info.session_token) setToken(info.session_token)
  }

  const setGuestToken = (guestToken) => {
    user.guestToken = guestToken
  }

  const setGuestInfo = (info) => {
    user.guestInfo = info
    if (info.session_token) setGuestToken(info.session_token)
  }

  return { user, userInfo, userCustomer, userSubscriptions, userCategories, token, guestToken, setUserInfo, setToken, setGuestToken, setGuestInfo, guestInfo }
})