import { defineStore } from 'pinia'
import { reactive, computed, watch, onMounted, ref } from 'vue'
import vueI18n from '@/locale'
import { languageMap } from '@/constant'
import { v4 as uuidv4 } from 'uuid'
import router from '@/router'
import dayjs from 'dayjs'
import { getMetaData } from '@/apis/app.js'

/** 应用相关配置 */
export const useAppStore = defineStore('app', () => {
  /** 应用配置 */
  const config = reactive({
    uuid: localStorage.getItem('uuid') || uuidv4(),
    geoInfo: null,
    devices: {}, // 设备信息
    metaData: [] // seo
  })

  const adCount = ref({
    //  广告计数
    banner: 1,
    lrec: 1
  })
  /** 语言配置 */
  const language = reactive({
    default: navigator?.language || import.meta.env.VITE_DEFAULT_LANGUAGE, // 默认语言（跟随系统）
    current: import.meta.env.VITE_DEFAULT_LANGUAGE, // 当前选择的语言
    map: languageMap, // 语言包
    code: null
  })

  /** VideoPlayer */
  const videoPlayerSetting = reactive({
    videoQuality: 1,
    audioChannel: 1,
    subtitleLanguage: 1,
    automaticallyPlayNextEpisode: 1,
    fontSize: 1
  })

  /** uuid */
  const uuid = computed(() => {
    localStorage.setItem('uuid', config.uuid)
    return config.uuid
  })
  /** 当前语言 */
  const currentLanguage = computed(() => language.map.get(language.current || language.default))

  /** 查找语言 */
  const filterLanguage = (languageKey, languageValue) => {
    let result = null
    try {
      languageMap.forEach((language, key) => {
        if (languageValue === key || language[languageKey] === languageValue) {
          result = language
          throw Error('exit find language')
        }
      })
    } catch (_) {
      return result
    }
  }

  /** 设置当前语言 */
  const setCurrentLanguage = (lang, jump = true) => {
    let [c, l] = location.pathname.split('/').filter((s) => s)
    if (['tc', 'sc', 'en'].includes(c)) l = c
    if (!lang) lang = filterLanguage('code', l).key
    language.current = lang
    vueI18n.global.locale = lang
    document.querySelector('html').lang = lang
    localStorage.setItem('currentLanguage', lang)
    const { name, params } = router.currentRoute.value
    const { code, day } = language.map.get(lang)
    dayjs.locale(day)
    language.code = code
    if (!params.language) {
      params.language = l
    }
    if (params.language !== code && name && jump)
      location.replace(location.href.replace(`/${params.language}/`, `/${code}/`))
  }

  /** 获取地理信息 */
  const getDevicesGeoInfo = () =>
    new Promise((resolve, reject) => {
      const nowTime = new Date().getTime()
      const countryInterval = setInterval(() => {
        if (sessionStorage.getItem('geoIp')) {
          try {
            config.geoInfo = JSON.parse(decodeURIComponent(sessionStorage.getItem('geoIp')))
            config.geoInfo.country = config.geoInfo.country === 'MO' ? 'US' : config.geoInfo.country
            if (localStorage.getItem('videoPlayerSetting')) {
              const localStorageVideoPlayerSetting = JSON.parse(
                decodeURIComponent(localStorage.getItem('videoPlayerSetting'))
              )
              for (const key in localStorageVideoPlayerSetting) {
                videoPlayerSetting[key] = localStorageVideoPlayerSetting[key]
              }
            }
            // else {
            //   const isMO = config?.geoInfo?.country?.toLocaleUpperCase() === 'MO'
            //   videoPlayerSetting.subtitleLanguage = !isMO && language.current === 'zh-CN' ? 1 : 2
            // }
          } catch (error) {
            reject(error)
          }
          sessionStorage.removeItem('geoIp')
          clearInterval(countryInterval)
          resolve(config.geoInfo)
        } else if (new Date().getTime() - nowTime >= 40000) {
          clearInterval(countryInterval)
          reject()
        }
      }, 100)
    })

  const devices = computed(() => config.devices)

  const setDevices = (devices) => {
    config.devices = devices
  }

  onMounted(() => {
    setCurrentLanguage('', false)
  })

  watch(
    videoPlayerSetting,
    (videoPlayerSetting) => {
      localStorage.setItem(
        'videoPlayerSetting',
        encodeURIComponent(JSON.stringify(videoPlayerSetting))
      )
    },
    {
      deep: true
    }
  )

  // watch(() => language.code, (language) => {
  //   const country = (config.geoInfo.country || '').toLocaleUpperCase()
  //   if (['US', 'CA'].includes(country)) {
  //     const jumpUrl = language === 'en' ? 'https://tvbanywherena.com/english?utm_source=tvbaw-global&utm_medium=website' : 'https://tvbanywherena.com/cantonese?utm_source=tvbaw-global&utm_medium=website'
  //     location.replace(jumpUrl)
  //   }
  // })

  /** seo */
  const setMetaData = async () => {
    try {
      const res = await getMetaData()
      config.metaData = Object.freeze(res || [])
    } catch (error) {
      // console.warn(error);
    }
  }

  const homeSeoMeta = computed(() =>
    config.metaData
      .find((v) => v.ref_type === 'homepage')
      ?.webpage_content?.find((v) => v.language === currentLanguage.value?.code)
  )
  const historySeoMeta = computed(() =>
    config.metaData
      .find((v) => v.ref_type === 'history')
      ?.webpage_content?.find((v) => v.language === currentLanguage.value?.code)
  )
  const favouriteSeoMeta = computed(() =>
    config.metaData
      .find((v) => v.ref_type === 'favourite')
      ?.webpage_content?.find((v) => v.language === currentLanguage.value?.code)
  )
  const searchSeoMeta = computed(() =>
    config.metaData
      .find((v) => v.ref_type === 'search')
      ?.webpage_content?.find((v) => v.language === currentLanguage.value?.code)
  )
  const epgMetaData = computed(() => config.metaData.filter((v) => v.ref_type === 'epg'))

  return {
    config,
    uuid,
    language,
    currentLanguage,
    filterLanguage,
    setCurrentLanguage,
    getDevicesGeoInfo,
    devices,
    setDevices,
    videoPlayerSetting,
    adCount,
    setMetaData,
    homeSeoMeta,
    historySeoMeta,
    favouriteSeoMeta,
    searchSeoMeta,
    epgMetaData
  }
})
