<script setup name="TvbAnywhereApp">
import { RouterView, useRoute } from 'vue-router'
import { useAppStore } from '@/stores/app'
import { useUserStore } from '@/stores/user'
import { NConfigProvider, darkTheme } from 'naive-ui'
import { useWindowSize } from '@vueuse/core'
import { provide, computed, ref, watch, watchEffect } from 'vue'
import { TvbAnywhereConfig } from '@tvb-sz/tvb-anywhere-headerfooter'
import { useSeoMeta, useHead } from '@vueuse/head'
import { getPath, getSeoLinks } from '@/utils'
import vueI18n from '@/locale'
const { t } = vueI18n.global

const route = useRoute()
const appStore = useAppStore()
const userStore = useUserStore()
const { width, height } = useWindowSize()
const isMobile = computed(() => {
  const isMobile = width.value <= 390
  return isMobile
})
const isTablet = computed(() => {
  const isTablet = width.value <= 834 && width.value > 390
  return isTablet
})
const env = import.meta.env.VITE_ENV

// console.log('isMobile=====', isMobile.value)
/** 是否平板  */
provide('isTablet', isTablet)
/** 是否移動端  */
provide('isMobile', isMobile)
/** 窗口寬度  */
provide('width', width)
/** 窗口高度  */
provide('height', height)
/** 登入彈窗  */
const showLoginModal = ref(false)
provide('showLoginModal', showLoginModal)

// seo
appStore.setMetaData()
const href = ref(location.origin + location.pathname)
const ogLocaleOption = {
  sc: 'zh_CN',
  tc: 'zh_TW ',
  en: 'en'
}
watchEffect(() => {
  const title = appStore.homeSeoMeta?.page_title || t('home.seo.title')
  const description = appStore.homeSeoMeta?.page_meta_description || t('home.seo.description')
  const ogTitle = appStore.homeSeoMeta?.page_meta_og_title || title
  const ogDescription = appStore.homeSeoMeta?.page_meta_og_description || description
  const robots = route.meta?.noRobots ? 'noindex' : 'max-image-preview:large'
  useSeoMeta({
    title,
    description,
    robots,
    ogLocale: ogLocaleOption[appStore.currentLanguage?.code] || '',
    ogType: 'website',
    ogTitle,
    ogDescription,
    ogUrl: href.value,
    ogSiteName: 'TVB Anywhere',
    ogImage: location.origin + getPath('/tvbaw_og.png'),
    ogImageAlt: ogTitle
  })
})

const head = ref({})
const setHead = () => {
  href.value = location.origin + location.pathname
  const link = [...getSeoLinks(href.value)]
  head.value = {
    link
  }
}
watch(
  () => route.fullPath,
  () => setHead()
)

const loginSuccess = (info) => {
  if (info) userStore.setUserInfo(info)
}

const loginGuestSuccess = (info) => {
  if (info) userStore.setGuestInfo(info)
}

useHead(head)
</script>

<template>
  <tvb-anywhere-config
    :env="env"
    @login-success="loginSuccess"
    @login-guest-success="loginGuestSuccess"
  >
    <n-config-provider
      abstract
      cls-prefix="tvb-anywhere"
      v-bind="appStore.currentLanguage.naive"
      :theme="darkTheme"
    >
      <n-dialog-provider>
        <n-message-provider><router-view /></n-message-provider>
      </n-dialog-provider>
      <TvbLoginDialog v-model:show="showLoginModal"></TvbLoginDialog>
    </n-config-provider>
  </tvb-anywhere-config>
</template>
